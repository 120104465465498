<template>
  <div class="v3-academic-person-groups">
    <ui-collection :view="view">
      <ui-card
        v-for="(item,i) in listItems"
        :key="i"
        v-bind="item.card"
        @click="$emit('click-group', item.group)"
      >
        <div class="group-teacher">
          <person-list-item
            avatar-size="50"
            :person="item.group.teacher"
            class="--nowrap"
          ></person-list-item>

          <!-- first two teachers -->
          <person-list-item
            v-for="extra_teacher in item.group.extra_teachers.slice(0,2)"
            :key="extra_teacher.id"
            avatar-size="50"
            :person="extra_teacher"
            class="--nowrap"
          ></person-list-item>

          <!-- Remaining teachers -->
          <details
            v-if="item.group.extra_teachers.length > 2"
            @click.stop="() => false"
          >
            <summary>Ver más</summary>
            <person-list-item
              v-for="extra_teacher in item.group.extra_teachers.slice(2)"
              :key="extra_teacher.id"
              avatar-size="50"
              :person="extra_teacher"
              class="--nowrap"
            ></person-list-item>
          </details>
        </div>

        <div class="counters">
          <template v-if="item.counters">
            <div
              v-for="(counterData, i) in item.counters"
              :key="i"
              class="counter-block"
            >
              <label>{{ counterData.label }}</label>
              <span class="value">{{ counterData.value }}</span>
            </div>
          </template>
          <template v-else>
            <div
              class="counter-block grade"
              v-show="!notasProhibidas"
            >
              <label>{{ $t("grade") }}</label>
              <span class="value">{{ item.group.course.grade ? item.group.course.grade.output : 'N.A.' }}</span>
            </div>

            <div
              class="counter-block screen-only"
              v-if="item.group.assignments"
            >
              <label>{{ $t("assignments") }}</label>
              <span class="value">{{ item.group.assignments.total }}</span>
            </div>

            <div
              class="counter-block screen-only"
              v-if="item.group.exams"
            >
              <label>{{ $t("exams") }}</label>
              <span class="value">{{ item.group.exams.total }}</span>
            </div>
          </template>
        </div>
      </ui-card>
    </ui-collection>
  </div>
</template>

<script>
import useApi from '@/modules/api/mixins/useApi';
import useI18n from '@/modules/i18n/mixins/useI18n';

import apiAcademicPerson from '@/modules/v3/api/academic/person.js';
import apiAcademicConsolidate from '@/modules/v3/api/academic/consolidate.js';
import apiJson from '/apis/v4/jsondb';

import { UiCollection, UiCard, UiItem } from '@/modules/ui/components';
import PersonListItem from '@/modules/core/components/Person/ListItem.vue';

export default {
  name: 'V3AcademicPersonGroups',
  mixins: [useApi, useI18n],

  api: {
    academic: apiAcademicPerson,
    jsondb: apiJson,
    consolidate: apiAcademicConsolidate,
  },

  components: {
    UiCollection,
    UiCard,
    UiItem,
    PersonListItem,
  },

  props: {
    personId: {
      type: String,
      required: true,
    },

    periodId: {
      type: String,
      required: true,
    },

    view: {
      type: String,
      required: false,
      default: 'grid',
      validator: (value) => ['list', 'grid'].indexOf(value) !== -1,
    },

    v4Grading: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideGrades: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideAssignments: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideExams: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  watch: {
    personAndPeriod: {
      immediate: true,
      handler() {
        this.fetchAreas();
      },
    },
  },

  computed: {
    personAndPeriod() {
      return this.personId + ':' + this.periodId;
    },

    listItems() {
      let retval = [];

      this.areas.forEach((area) => {
        area.subject.forEach((subject) => {
          subject.course.forEach((course) => {
            let courseCard =
              course.data && course.data.card ? course.data.card : {};

            course.group.forEach((group) => {
              group.course = course;
              group.subject = subject;
              group.area = area;

              let groupCard =
                group.data && group.data.card ? group.data.card : {};

              let card = Object.assign(
                {
                  color: 'var(--ui-color-primary)',
                  tintOpacity: 0.5,
                },
                courseCard,
                groupCard,
                {
                  text: subject.name,
                  class: '--cover',
                }
              );

              let counters = undefined;
              if (this.v4Grading) {
                let nProductos = 0;
                let nGradedProductos = 0;
                course.unidades.forEach((unidad) => {
                  unidad.productos.forEach((producto) => {
                    nProductos++;
                    if (producto.calificacion) {
                      nGradedProductos++;
                    }
                  });
                });

                counters = [
                  { label: 'Productos', value: nProductos },
                  { label: 'Calificados', value: nGradedProductos },
                  {
                    label: 'Nota',
                    value: group.course?.grade?.output || 'N.A.',
                  },
                ];
              }

              retval.push({
                card: card,
                group: group,
                counters,
              });
            });
          });
        });
      });

      return retval;
    },
  },

  data() {
    return {
      areas: [],
      unidades: [],
      notasProhibidas: true,
    };
  },

  methods: {
    async fetchUnidades(courseIds) {
      this.unidades = await this.$api.jsondb.query({
        from: 'unidades',
        match: {
          academicCourseId: courseIds,
          periodId: this.periodId,
        },
        properties: [
          'id',
          'academicCourseId',
          {
            productos: {
              from: 'unidades-productos',
              on: {
                unidadId: 'id',
              },
              properties: [
                '*',
                {
                  calificacion: {
                    single: true,
                    from: 'unidad-producto-calificaciones',
                    on: { unidadProductoId: 'id' },
                    match: { personId: this.personId },
                    properties: '*',
                  },
                },
              ],
            },
          },
        ],
      });
    },

    async fetchAreas() {
      const areas = await this.$api.academic.getAreas(this.personId, {
        period: this.periodId,
        grades: this.hideGrades ? 0 : 1,
        assignments: this.hideAssignments ? 0 : 1,
        exams: this.hideExams ? 0 : 1,
      });

      /* Si hide-grades esta puesto, no mostrar la casilla de nota (ni siquiera con N.A.) */
      if (this.hideGrades) {
        this.notasProhibidas = true;
      } else {
        const primerpermiso =
          await this.$api.consolidate.getAcademicConsolidateStudentCourse(
            this.personId,
            areas?.[0]?.subject?.[0]?.course?.[0]?.id,
            this.periodId
          );

        if (primerpermiso) {
          this.notasProhibidas = false;
        }
      }

      // Fetch and merge unidades + calificaciones
      if (this.v4Grading) {
        const courseIds = [];
        const courseHash = {};
        areas.forEach((area) => {
          area.subject.forEach((subject) => {
            subject.course.forEach((course) => {
              courseIds.push(course.id);
              course.unidades = [];
              courseHash[course.id] = course;
            });
          });
        });

        await this.fetchUnidades(courseIds);
        this.unidades.forEach((unidad) => {
          courseHash[unidad.academicCourseId].unidades.push(unidad);
        });
      }

      this.areas = areas;
    },
  },
};
</script>

<style lang="scss">
.v3-academic-person-groups {
  .group-teacher {
    position: relative;
    top: -20px;
    margin-bottom: -5px;

    h1.text-primary {
      font-family: var(--ui-font-secondary);
      font-size: 15px;
      font-weight: 500;
      opacity: 0.9;
    }
  }

  .counters {
    display: flex;
    flex-wrap: wrap;

    .counter-block {
      flex: 1;

      label,
      .value {
        display: block;
      }

      label {
        font-size: 11px;
        opacity: 0.5;
      }
    }
  }
}

@media only screen and (max-width: 810px) {
  .screen-only {
    display: none;
  }
}
</style>